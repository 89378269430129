<template>
  <div>
    <ek-table
      @delete-selected="deletePoints"
      :columns="columns"
      :items="sellPointsList"
      @details="sellPointsDetail"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
      <template slot="items.isBlocked" slot-scope="{ value }">
        <StatusBadge :value="value" :options="sellPointStatus" />
      </template>
      <template slot="items.isHidden" slot-scope="{ value }">
        <StatusBadge :value="value" :options="sellPointStatusHidden" />
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      sellPointsList: (state) => state.sellPoints.sellPointsList,
      columns: (state) => state.sellPoints.columns,
      sellPointStatus: (state) => state.sellPoints.sellPointStatus,
      sellPointStatusHidden:(state)=> state.sellPoints.sellPointStatusHidden
    }),
  },
  methods: {
    deletePoints(e) {
      this.deleteRangeSellPoints(e);
    },
    sellPointsDetail({ row }) {
      // console.log(row);
      this.$router.push(`/admin/sellpoints/${row.id}`);
    },
    ...mapActions([
      "getAllSellPoints",
      "deleteRangeSellPoints",
      "getByIdSellPoint",
    ]),
  },
  created() {
    this.getAllSellPoints();
  },
};
</script>

<style></style>
